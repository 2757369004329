import type { RouteLocationNormalizedGeneric } from 'vue-router';

export default defineNuxtRouteMiddleware(
   async (to: RouteLocationNormalizedGeneric) => {
      const { therapistInfo } = storeToRefs(useTherapistInfoStore());
      const { isTherapistAuthenticated } = useAuthTherapistStorage();
      const { getTherapistInfo, getTherapistProfile } = useTherapistInfoStore();
      if (isTherapistAuthenticated?.value) {
         if (!therapistInfo.value) {
            await Promise.all([getTherapistInfo(), getTherapistProfile()]);
         }
         if (
            therapistInfo.value?.timezone == null ||
            therapistInfo.value?.timezone == ''
         ) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const { updateTimeZone } = useTimeZoneStore();
            await updateTimeZone(timeZone);
         }
      } else {
         return navigateTo({ name: 'auth-therapist-login' });
      }
   }
);
